import request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * get 查询商品组列表
 * @param params
 * @returns {Promise<*>}
 */
export const getShopGroupService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/group/shopQuery?brandIds='+params.brandIds+'&attributeIds='+params.attributeIds+'&price2='+params.price2+'&specs='+params.specs+'&cid1='+params.cid1+'&cid2='+params.cid2+'&cid3='+params.cid3+'&cid4='+params.cid4+'&cid5='+params.cid5+'&size='+params.size+'&page='+params.page+'&isNew='+params.isNew+'&isSale='+params.isSale+'&isOld='+params.isOld+'&sortBy='+params.sortBy))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 查询商品组品牌列表信息
 * @param params
 * @returns {Promise<*>}
 */
export const getBrandCategoryService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/brand/category/brand/id?brandId='+params.brandId+'&cid1='+params.cid1))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 查询商品组品牌列表信息标签
 * @param params
 * @returns {Promise<*>}
 */
export const getBrandCategoryConfService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/brand/category/brand/conf?brandId='+params.brandId+'&categoryId='+params.categoryId))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 查询商品组品牌列表信息标签选中
 * @param params
 * @returns {Promise<*>}
 */
export const getBrandCreateAllService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/myBrand/updateAll?brandId='+params.brandId+'&categoryIds='+params.categoryIds))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 查询商品组详情
 * @param params
 * @returns {Promise<*>}
 */
export const getShopGroupDetailsService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/shop/group/id?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get设置商品组标签
 * @param params
 * @returns {Promise<*>}
 */
export const getGroupLabelService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/group/set/label?id='+params.id+'&state='+params.state+'&type='+params.type))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
