<template>
  <div>
    <!--s: details-->
    <div class="product-details" v-if="JSON.stringify(detailsObj) !== '{}'">
      <div class="product-info" :style="infoHeight">
        <!--s: Product Image PC-->
        <div class="details-image PC">
          <!-- <div
            class="image"
            :style="'height:' + imgList.length * 595 + 'px'"
            @click="onBigImage"
          > -->
          <div
            class="image"
            @click="onBigImage"
          > 
            <div
              class="box"
              id="box"
              :style="'transform: translateY(-' + offsetY + 'px)'"
            >
              <img
                :src="img"
                v-lazy="img"
                :key="key"
                v-for="(img, key) in imgList"
              />
            </div>
          </div>
        </div>
        <!--e: Product Image PC-->

        <!--s: Product information-->
        <div class="details-information">
          <h1 class="details-title h5">
            <a class="h5-back" href="javascript:" @click="$router.go(-1)">
            </a>
            <div>{{ detailsObj.brandName }}</div>
          </h1>
          <div
            class="details-info h5"
            :style="!detailsVisible && isPhone() ? '-webkit-line-clamp: 2;' : 
              !detailsVisible && !isPhone() ? '-webkit-line-clamp: 2; display: none !important' :
              detailsVisible && !isPhone() ? 'display: none !important' : ''"
          >
            {{ detailsObj.details }}
          </div>
          <div class="details-info-btn h5">
            <a href="javascript:" @click="onDetailsMore">
              {{ !detailsVisible ? $t("details.viewMore") : $t("details.hide")}}
              <i :class="!detailsVisible ? 'down' : 'up'"></i
            ></a>
            <div class="details-top">
              <div class="details-label" v-if="detailsObj.isStar">
                <i class="icon state"></i>
              </div>
              <div class="details-label" v-if="detailsObj.isShow">
                <i class="icon draft"></i>
              </div>
              <div class="details-label" v-if="detailsObj.isNew">
                <span class="label green">{{ $t("details.newIn") }}</span>
              </div>
              <div class="details-label" v-if="detailsObj.isSale">
                <span class="label red">{{ $t("details.saleIn") }}</span>
              </div>
            </div>
          </div>
          <div class="details-top PC">
            <div class="details-label" v-if="detailsObj.isStar">
              <i class="icon state"></i>
              <p class="text">{{ $t("details.star") }}</p>
            </div>
            <div class="details-label" v-if="detailsObj.isShow">
              <i class="icon draft"></i>
              <p class="text">{{ $t("details.show") }}</p>
            </div>
            <div class="details-label" v-if="detailsObj.isNew">
              <span class="label green">{{ $t("details.newIn") }}</span>
            </div>
            <div class="details-label" v-if="detailsObj.isSale">
              <span class="label red">{{ $t("details.saleIn") }}</span>
            </div>
          </div>
          <!--s: Product Image h5-->
          <div class="details-image h5">
            <div class="image" @click="productBig = true">
              <van-swipe
                class="my-swipe"
                :autoplay="3000"
                indicator-color="#dbdbdb"
              >
                <van-swipe-item :key="key" v-for="(img, key) in imgList">
                  <img :src="img" v-lazy="img" />
                </van-swipe-item>
              </van-swipe>
            </div>
          </div>
          <!--e: Product Image h5-->
          <div class="details-text"
          :style="isPhone() ? 'display: none !important' : ''">
            <a class="details-title" @click="onBrandList">{{
              detailsObj.brandName
            }}</a>
            <h2 class="details-describe">{{ detailsObj.name }}</h2>
            <h2 class="details-describe channel">{{ detailsObj.channelName }}</h2>
            <div
              class="details-info"
              :style="!detailsVisible ? '-webkit-line-clamp: 3;' : ''"
              ref="detailDom"
            >
              {{ detailsObj.details }}
            </div>
            <div class="details-info-btn" v-if="showDetailBtn">
              <a href="javascript:" @click="onDetailsMore"
                >{{ !detailsVisible ? $t("details.viewMore") : $t("details.hide")
                }}<i :class="!detailsVisible ? 'down' : 'up'"></i
              ></a>
            </div>
          </div>
          <div
            class="details-size"
            v-show="!detailsVisible"
            :style="!showDetailBtn && !isPhone() ? 'margin-top:70px' : ''"
          >
            <a href="javascript:" style="float: left">{{
              detailsObj.spuList[isNumber].specs
            }}</a>
            <a
              href="javascript:"
              class="guide"
              @click="onShow(detailsObj.spuList[isNumber].id)"
            >
              {{ $t("details.sizeGuide") }}
            </a>
          </div>
          <div class="details-size-li" v-show="!detailsVisible">
            <a
              href="javascript:"
              :key="key"
              v-for="(productSize, key) in detailsObj.spuList[isNumber].specsArr"
              >{{ productSize }}</a
            >
          </div>
          <div class="details-list-box" v-show="!detailsVisible">
            <div
              class="box-name"
              :class="detailsObj.spuList[isNumber].promotion ? 'box-name-bg' : ''"
            >
              <div class="name-flax">
                <div class="box">
                  <p class="language">
                    {{ detailsObj.spuList[isNumber].channelName }}
                  </p>
                  <div class="currency">
                    <p
                      class="default"
                      :class="
                        detailsObj.spuList[isNumber].promotion ? 'del-line' : ''
                      "
                    >
                      {{ detailsObj.spuList[isNumber].currency
                      }}{{ detailsObj.spuList[isNumber].price }}
                    </p>
                    <p
                      class="discount"
                      v-if="detailsObj.spuList[isNumber].promotion"
                    >
                      {{ detailsObj.spuList[isNumber].currency
                      }}{{ detailsObj.spuList[isNumber].price }}
                    </p>
                  </div>
                  <p
                    class="shipping PC"
                    v-if="detailsObj.spuList[isNumber].description"
                  >
                    {{ detailsObj.spuList[isNumber].description }}
                  </p>
                </div>
              </div>
              <div
                class="name-discount PC"
                v-if="detailsObj.spuList[isNumber].promotion"
              >
                <p v-if="langCode === 'EN'">
                  {{ detailsObj.spuList[isNumber].promotion }}<br />
                  off
                </p>
                <p v-if="langCode === 'CN'">
                  {{ (100 - detailsObj.spuList[isNumber].promotion) / 10 }}折
                </p>
              </div>
              <div class="name-buy">
                <a :href="detailsObj.spuList[isNumber].opUrl" target="_blank">{{
                  $t("details.buy")
                }}</a>
              </div>
            </div>
            <div class="box-btn">
              <a
                href="javascript:"
                @click="onSwitchUp()"
                :class="isNumber === 0 ? 'arrow-up' : 'arrow-up-black'"
              ></a>
              <a
                href="javascript:"
                @click="onSwitchDown()"
                :class="
                  isNumber === detailsObj.spuList.length - 1
                    ? 'arrow-down'
                    : 'arrow-down-black'
                "
              ></a>
            </div>
          </div>
          <div class="details-view" v-show="!detailsVisible">
            <a
              href="javascript:"
              v-if="detailsObj.spuList.length > 1"
              @click="contrastVisible = true"
              v-html="
                $t('details.viewAll', { number: detailsObj.spuList.length })
              "
            ></a>
          </div>
          <div class="details-link" v-show="!detailsVisible">
            <a href="javascript:" class="share" @click="shareVisible = true">
            {{
              $t("details.share")
            }}
            </a>
            <div class="share-box" v-if="shareVisible">
              <a
                href="javascript:"
                class="hide"
                @click="shareVisible = !shareVisible"
                >{{ $t("offers.hide") }}</a
              >
              <div class="box">
                <a href="javascript:" class="face-book"></a>
                <a href="javascript:" class="pinterest"></a>
                <a href="javascript:" class="twitter"></a>
                <a href="javascript:" @click="onShare('wb')" class="sina"></a>
                <a href="javascript:" @click="onShare('wx')" class="wx"></a>
                <a href="javascript:" class="email"></a>
                <a href="javascript:" class="icon-code"></a>
                <a href="javascript:" class="link"></a>
              </div>
              <span
                class="h5 hide-h5"
                @click="shareVisible = !shareVisible"
                >X</span>
            </div>
            <div class="details-content">
              <div class="details-label" @click="onSetReminder(detailsObj.id)">
                <p class="text alert">{{ $t("details.setAlert") }}</p>
                <i
                  class="icon"
                  :class="detailsObj.alert === 0 ? 'remind-grey' : 'remind-black'"
                ></i>
              </div>
              <div class="details-label" @click="onLike(detailsObj)">
                <p class="text">{{ $t("details.addWishlist") }}</p>
                <i
                  class="icon"
                  :class="detailsObj.isWish === 1 ? 'like-red' : 'like-grey'"
                ></i>
              </div>
            </div>
          </div>

          <div class="details-alerts-box" v-if="alertsVisible">
            <div class="alerts-title">{{ $t("userCenter.selectSize") }}</div>
            <div class="alerts-size">
              {{ detailsObj.spuList[isNumber].specs }}
            </div>
            <div class="alerts-change">
              <span class="name">{{ $t("userCenter.preferPrice") }}</span>
              <div
                class="alerts-radio"
                @click="
                  priceChange ? (priceChange = false) : (priceChange = true)
                "
              >
                <i
                  class="label-icon"
                  :class="priceChange ? 'radioOn' : 'radio'"
                ></i>
                <span
                  class="label-name"
                  :style="
                    priceChange
                      ? 'font-family: Montserrat-SemiBold,FZLT-SemiBold;'
                      : ''
                  "
                  >{{ $t("userCenter.priceChange") }}</span
                >
              </div>
            </div>
            <div class="alerts-price">
              <span class="label"
                >{{ detailsObj.spuList[isNumber].currency }}0</span
              >
              <div class="price">
                <Slider
                  v-if="detailsObj"
                  v-model="priceValue"
                  show-tip="never"
                  :step="1"
                  :min="0"
                  :max="
                    Number(
                      detailsObj.spuList[this.isNumber].price.replace(/,/g, '')
                    ) + 1000
                  "
                ></Slider>
              </div>
              <span class="label"
                >{{ detailsObj.spuList[isNumber].currency }}{{ priceValue }}</span
              >
            </div>
            <div class="alerts-btn">
              <Button
                shape="circle"
                :loading="loadingStatus"
                @click="onGoodsRemind"
              >
                <span v-if="!loadingStatus">{{ $t("userCenter.setAlert") }}</span>
                <span v-else>{{ $t("userCenter.setAlert") }}</span>
              </Button>
            </div>
            <a
              href="javascript:"
              class="close"
              @click="alertsVisible = false"
            ></a>
          </div>
          <div class="product-contrast" v-if="contrastVisible">
            <a
              href="javascript:"
              class="close"
              @click="contrastVisible = false"
            ></a>
            <div class="contrast-box">
              <div
                class="contrast"
                :key="key"
                v-for="(spuList, key) in token
                  ? detailsObj.spuList
                  : detailsObj.spuList.slice(0, 3)"
              >
                <div class="details-size">
                  <a href="javascript:" style="float: left">{{
                    spuList.specs
                  }}</a>
                  <a href="javascript:" class="guide" @click="onShow(spuList.id)">
                    {{ $t("details.sizeGuide") }}
                  </a>
                </div>
                <div class="details-size-li">
                  <a
                    href="javascript:"
                    :key="index"
                    v-for="(productSize, index) in spuList.specsArr"
                    >{{ productSize }}</a
                  >
                </div>
                <div
                  class="box-name"
                  :class="spuList.promotion ? 'box-name-bg' : ''"
                >
                  <div class="name-flax">
                    <div class="box">
                      <p class="language">{{ spuList.channelName }}</p>
                      <div class="currency">
                        <p
                          class="default"
                          :class="spuList.promotion ? 'del-line' : ''"
                        >
                          {{ spuList.currency }}{{ spuList.price }}
                        </p>
                        <p class="discount" v-if="spuList.promotion">
                          {{ spuList.currency }}{{ spuList.price }}
                        </p>
                      </div>
                      <p class="shipping PC">{{ spuList.description }}</p>
                    </div>
                  </div>
                  <div class="name-discount" v-if="spuList.promotion">
                    <p v-if="langCode === 'EN'">
                      {{ spuList.promotion }}<br />
                      off
                    </p>
                    <p v-if="langCode === 'CN'">
                      {{ (100 - spuList.promotion) / 10 }}折
                    </p>
                  </div>
                  <div class="name-buy">
                    <a :href="detailsObj.spuList.opUrl" target="_blank">{{
                      $t("details.buy")
                    }}</a>
                  </div>
                </div>
              </div>
              <div class="contrast-login" v-if="!token">
                <h1 class="title">{{ $t("details.tips") }}</h1>
                <p class="text">{{ $t("details.tipsText") }}</p>
                <div class="tips-btn">
                  <a href="javascript:" @click="$router.push('/register')">{{
                    $t("carousel.signUp")
                  }}</a>
                  /
                  <a
                    href="javascript:"
                    @click="$router.push('/login')"
                    class="arrow"
                    >{{ $t("carousel.login") }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--e: Product information-->

        <div class="product-details-big" v-if="productBig">
          <div class="image" @click="onSmallImage">
            <img
              :src="img"
              v-lazy="img"
              :key="key"
              v-for="(img, key) in imgList"
            />
          </div>
        </div>

        <!--s: Size guide-->
        <div class="layout-size-guide" v-if="sizeGuideVisible">
          <div class="low-lining" @click="onClose"></div>
          <div class="guide-body">
            <a href="javascript:" @click="onClose" class="close"></a>
            <div class="guide-carousel">
              <div class="guide-box">
                <h1 class="name">{{ $t("details.sizeGuideTitle") }}</h1>
                <div class="list">
                  <SizeRotation :data="detailsObj.spuList" />
                </div>
              </div>
            </div>
            <div class="guide-size-list">
              <h1 class="name" v-if="sizeGuideList">{{ sizeGuideList.name }}</h1>
              <div class="list-body">
                <SizeGuide
                  v-if="sizeGuideList && sizeGuideList.nameList"
                  :data="sizeGuideList.nameList"
                />
              </div>
              <div class="guide-text">
                <p v-html="$t('details.sizeGuideText')"></p>
              </div>
            </div>
          </div>
        </div>
        <!--e: Size guide-->
      </div>

      <div class="product-attribute PC">
        <div class="attribute-title">
          <h1 class="name">{{ groupBrandList.brandName }}</h1>
          <a href="javascript:" class="view-all" @click="onBrandLink">{{
            $t("header.viewAll")
          }}</a>
        </div>
        <div class="attribute-body">
          <DetailsAttribute
            :data="groupBrandList.productList"
            id="groupBrandId"
            @on-links="onCarousel"
            @on-like="onLike"
          />
        </div>
      </div>
      <div class="product-attribute PC">
        <div class="attribute-title">
          <h1 class="name">{{ groupRelevantList.cid2Name }}</h1>
          <a href="javascript:" class="view-all" @click="onTypeLink">{{
            $t("header.viewAll")
          }}</a>
        </div>
        <div class="attribute-body">
          <DetailsAttribute
            :data="groupRelevantList.productList"
            id="groupRelevantId"
            @on-links="onCarousel"
            @on-like="onLike"
          />
        </div>
      </div>
      <div class="product-attribute PC">
        <div class="attribute-title">
          <h1 class="name">Style</h1>
          <!--                <a href="javascript:" class="view-all">{{ $t('header.viewAll') }}</a>-->
        </div>
        <div class="attribute-body bottom">
          <DetailsAttribute
            :data="groupTitleList.productList"
            id="groupTitleId"
            @on-links="onCarousel"
            @on-like="onLike"
          />
        </div>
      </div>
    </div>
    <!--e: details-->
    <div v-else class="loading"></div>
  </div>
</template>
<script>
import ProductDetails from "./details";
export default ProductDetails;
</script>
<style lang="less" scoped>
@import "details";
</style>
