/**
 * 产品详情
 */
import Vue from "vue";
import { Slider, Button } from "iview";
import {
  Swipe as vanSwipe,
  SwipeItem as vanSwipeItem,
  Button as vanButton,
  Slider as vanSlider
} from "vant";
import { getShopGroupDetailsService } from "@/service/spu-group-service";
Vue.component("Slider", Slider);
Vue.component("Button", Button);
Vue.component("VanSwipe", vanSwipe);
Vue.component("vanSwipeItem", vanSwipeItem);
Vue.component("vanButton", vanButton);
Vue.component("vanSlider", vanSlider);
import VueLazyLoad from "vue-lazyload";
Vue.use(VueLazyLoad, {
  loading: require("@/assets/Loading/seize-seat-img.jpg")
});
import { mapMutations, mapState } from "vuex";
import Cookies from "js-cookie";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import { addGoodsCollectionService } from "@/service/goods-collection-service";
import {
  addGoodsRemindService,
  delGoodsRemindService,
  getSizeGuideListService,
  getGroupRelevantListService
} from "@/service/goods-remind-service";
import SizeRotation from "@/components/SizeRotation/sizeRotation.vue";
import SizeGuide from "@/components/SizeGuide/sizeGuide.vue";
import DetailsAttribute from "@/components/DetailsAttribute/detailsAttribute.vue";
import router from "@/router";
import { wbAccess, wxAccess } from "@/utils/share";
import { isPhone } from "@/utils/tools";
import { SOURCE_NAME } from "@/service/public/enum";
const ProductDetails = {
  name: "ProductDetails",
  components: {
    SizeRotation,
    SizeGuide,
    DetailsAttribute
  },
  computed: {
    ...mapState({
      langCode: (state) => state.language.code, //获取状态管理语言代码
      currencyCode: (state) => state.system.currencyCode, //获取状态管理币种代码
      productId: (state) => state.product.productId //获取状态管理商品id
    })
  },
  data() {
    return {
      token: Cookies.get("token"),
      infoHeight: "",
      loadingStatus: false,
      detailsVisible: false, //默认展示
      showDetailBtn: false,
      productBig: false,
      shareVisible: false,
      detailsObj: {}, //详情对象
      imgList: [],
      isNumber: 0,
      priceChange: false,
      alertsVisible: false, //提醒弹框可显示
      priceValue: 0,
      contrastVisible: false, //商品对比可显示
      sizeGuideVisible: false, //尺码对照表可显示
      sizeGuideList: [], //尺码对照表数据集
      groupBrandList: {}, //商品品牌推荐列表数据集
      groupRelevantList: {}, //商品相关推荐列表数据集
      groupTitleList: {}, //商品相关推荐列表数据集
      offsetY: 0,
      isPhone: isPhone
    };
  },
  created() {
    /**
     * 订阅 登录操作token
     */
    $event.$on(EVENT_NAME.LOGIN_TOKEN, () => {
      this.token = Cookies.get("token");
    });
    /**
     * 订阅  更新商品详情
     */
    $event.$on(EVENT_NAME.PRODUCT_GROUP_DETAILS, (id) => {
      this.onShopGroupDetails(id).then();
    });
    if (this.$route.query.id === "" || this.$route.query.id === undefined) {
      router.back();
    } else {
      this.onShopGroupDetails(this.$route.query.id).then();
      this.onGroupBrandList(this.$route.query.id, 1).then();
      this.onGroupRelevantList(this.$route.query.id, 2).then();
      this.onGroupTitleList(this.$route.query.id, 3).then();
    }
  },
  mounted() {
    document.documentElement.scrollTop = document.body.scrollTop = 0; //定位滚动条到头部
    document.addEventListener("mousewheel", this.handleScroll);
    if (
      document.documentElement.scrollTop === 0 &&
      document.body.scrollTop === 0 && !isPhone()
    ) {
      document.body.style.overflow = "hidden";
    }
    this.infoStyle();
    window.addEventListener("resize", this.infoStyle, true);
  },
  methods: {
    ...mapMutations("userCenter", ["SET_ALERT_NEW", "DEL_ALERT_NEW"]),
    ...mapMutations("product", [
      "SET_SECOND_TYPE",
      "SET_TERTIARY_TYPE",
      "SET_PRODUCT_ID",
      "SET_MENU_TYPE",
      "SET_LABEL",
      "SET_MENU_ID",
      "SET_SOURCE",
      "SET_BRAND_ID",
      "SET_IS_NEW",
      "SET_IS_SALE",
      "SET_IS_OLD",
      "SET_LEVEL",
      "SET_SELECTION_ID"
    ]),
    infoStyle() {
      // if (window.innerHeight - 104 > 550) {
      //   this.infoHeight = "height:" + (window.innerHeight - 104) + "px";
      // } else {
      //   this.infoHeight = "height:auto";
      // }
    },
    handleScroll(e) {
      if (
        document.documentElement.scrollTop === 0 &&
        document.body.scrollTop === 0
      ) {
        this.offsetY += e.deltaY;
      }
      if (
        e.deltaY < 0 &&
        document.documentElement.scrollTop === 0 &&
        document.body.scrollTop === 0 && !isPhone()
      ) {
        if (this.offsetY < 0) {
          this.offsetY = 0;
        }
        document.body.style.overflow = "hidden";
      } else {
        let pxNum = window.innerHeight * 0.9;

        // if (window.innerWidth < 1441) {
        //   pxNum = 600;
        // } else if (window.innerWidth < 1601){
        //   pxNum = 800;
        // } else {
        //   pxNum = 900;
        // }
        if (this.offsetY >= (this.imgList.length - 1) * pxNum) {
          this.offsetY = (this.imgList.length - 1) * pxNum;
          document.body.style.overflow = "visible";
        }
      }
    },
    showDetailBtnFun() {
      this.$nextTick(() => {
        this.detailDom = this.$refs.detailDom;
        if (this.detailDom) {
          this.showDetailBtn =
          this.detailDom.clientHeight < this.detailDom.scrollHeight;
        }
      });
    },
    /**
     * 放大图片
     */
    onBigImage() {
      this.productBig = true;
      document.removeEventListener("mousewheel", this.handleScroll, false);
      // if(document.documentElement.scrollTop===0 && document.body.scrollTop === 0){
      //     document.body.style.overflow = 'hidden'
      // }
    },
    /**
     * 收起图片
     */
    onSmallImage() {
      this.productBig = false;
      document.addEventListener("mousewheel", this.handleScroll);
      // document.body.style.overflow = 'visible'
    },
    /**
     * 开启弹框
     */
    onShow(id) {
      document.removeEventListener("mousewheel", this.handleScroll, false);
      document.body.style.overflow = "visible";
      this.onSizeGuideList(id).then();
    },
    /**
     * 分享配置
     * @param config
     */
    onShare(config) {
      switch (config) {
        case "wb":
          wbAccess(this.detailsObj.name, this.detailsObj.images, location.href);
          break;
        case "wx":
          wxAccess(this.detailsObj.name);
          break;
      }
    },
    /**
     * 关闭弹框
     */
    onClose() {
      document.addEventListener("mousewheel", this.handleScroll);
      this.sizeGuideVisible = false;
    },
    /**
     * 切换下一个
     */
    onSwitchUp() {
      this.offsetY = 0;
      if (this.isNumber !== 0) {
        this.isNumber--;
      }
      this.imgList = this.detailsObj.spuList[this.isNumber].images.split(",");
    },
    /**
     * 切换上一个
     */
    onSwitchDown() {
      this.offsetY = 0;
      if (this.isNumber === 2 && !Cookies.get("token")) {
        $event.$emit(EVENT_NAME.HEADER_TIPS_STATUS);
        return;
      }
      if (this.isNumber !== this.detailsObj.spuList.length - 1) {
        this.isNumber++;
      }
      this.imgList = this.detailsObj.spuList[this.isNumber].images.split(",");
    },
    /**
     * 点击喜爱
     */
    onLike(obj) {
      if (!Cookies.get("token")) {
        $event.$emit(EVENT_NAME.HEADER_TIPS_STATUS);
        return;
      }
      this.onGoodsCollection(obj).then();
    },
    /**
     * 设置提醒
     */
    onSetReminder(id) {
      if (!Cookies.get("token")) {
        $event.$emit(EVENT_NAME.HEADER_TIPS_STATUS);
        return;
      }
      if (this.detailsObj.alert === 0) {
        this.alertsVisible = true;
      } else {
        this.onDelGoodsRemindList(id).then();
      }
    },
    /**
     * 货币符号
     */
    onCurrencySymbol() {
      switch (this.currencyCode) {
        case "gbp":
          return "￡";
        case "usd":
          return "$";
        case "eur":
          return "€";
        case "cny":
          return "¥";
      }
    },
    /**
     * 查询详情
     * @param id
     */
    onCarousel(id) {
      this.SET_PRODUCT_ID(id);
      this.onShopGroupDetails(id).then();
      this.$router.push("/product/details?id=" + id);
      document.documentElement.scrollTop = document.body.scrollTop = 0; //定位滚动条到头部
    },
    /**
     * 展开更多信息
     */
    onDetailsMore() {
      this.detailsVisible
        ? (this.detailsVisible = false)
        : (this.detailsVisible = true);
      this.alertsVisible = false;
    },
    /**
     * 跳转品牌列表页
     */
    onBrandList() {
      this.SET_LABEL(this.detailsObj.brandName);
      this.SET_BRAND_ID(this.detailsObj.brandId);
      this.SET_IS_NEW("");
      this.SET_IS_SALE("");
      this.SET_IS_OLD("");
      this.SET_LEVEL("");
      $event.$emit(EVENT_NAME.FOOTER_BANNER_QUERY);
      $event.$emit(EVENT_NAME.HEADER_MENU_TYPE);
      this.SET_SOURCE(SOURCE_NAME.MENU_LINK_LIST);
      this.$router.push("/brands/list");
    },
    onBrandLink() {
      this.SET_LABEL(this.groupBrandList.brandName);
      this.SET_BRAND_ID(this.groupBrandList.brandId);
      this.SET_IS_NEW("");
      this.SET_IS_SALE("");
      this.SET_IS_OLD("");
      this.SET_LEVEL("");
      $event.$emit(EVENT_NAME.FOOTER_BANNER_QUERY);
      $event.$emit(EVENT_NAME.HEADER_MENU_TYPE);
      this.SET_SOURCE(SOURCE_NAME.MENU_LINK_LIST);
      this.$router.push("/brands/list");
    },
    onTypeLink() {
      let secondId = "";
      let secondName = "";
      let tertiaryId = "";
      let tertiaryName = "";
      let label = "";
      let menuType = "";
      this.SET_MENU_ID(this.groupRelevantList.cid1);
      menuType = this.groupRelevantList.cid1;
      this.SET_LABEL(this.groupRelevantList.cid2Name);
      label = this.groupRelevantList.cid2Name;
      this.SET_SOURCE(SOURCE_NAME.SUPER_SALE);
      this.SET_SECOND_TYPE({
        categoryId: this.groupRelevantList.cid2,
        name: this.groupRelevantList.cid2Name
      });
      secondId = this.groupRelevantList.cid2;
      secondName = this.groupRelevantList.cid2Name;
      this.SET_TERTIARY_TYPE({
        categoryId: "",
        name: ""
      });
      tertiaryId = "";
      tertiaryName = "";
      this.$router.push(
        "/product/list?menuType=" +
          menuType +
          "&secondId=" +
          secondId +
          "&secondName=" +
          secondName +
          "&tertiaryId=" +
          tertiaryId +
          "&tertiaryName=" +
          tertiaryName +
          "&label=" +
          label +
          "&page=" +
          1
      );
    },
    /**
     * 查询商品组详情
     * @returns {Promise<void>}
     */
    async onShopGroupDetails(id) {
      try {
        let params = {
          id: id
        };
        const data = await getShopGroupDetailsService(params);
        this.detailsObj = data;
        this.showDetailBtnFun();
        this.priceValue = Number(
          data.spuList[this.isNumber].price.replace(/,/g, "")
        );
        this.imgList = data.spuList[this.isNumber].images.split(",");
        let historyArray = JSON.parse(localStorage.getItem("history"));
        if (!historyArray || historyArray.length === 0) {
          let array = [];
          array.push(id);
          localStorage.setItem("history", JSON.stringify(array));
          $event.$emit(EVENT_NAME.HISTORY_QUERY);
        } else {
          if (historyArray.indexOf(id) === -1) {
            if (historyArray.length >= 30) {
              historyArray.pop();
            }
            historyArray.unshift(id);
            localStorage.setItem("history", JSON.stringify(historyArray));
            $event.$emit(EVENT_NAME.HISTORY_QUERY);
          } else {
            historyArray.splice(historyArray.indexOf(id), 1);
            historyArray.unshift(id);
            localStorage.setItem("history", JSON.stringify(historyArray));
            $event.$emit(EVENT_NAME.HISTORY_QUERY);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 新增商品收藏
     * @returns {Promise<void>}
     */
    async onGoodsCollection(obj) {
      try {
        console.log(obj.isWish);
        let params = {
          productId: obj.id,
          type: obj.isWish === 0 ? 1 : 0
        };
        const { code } = await addGoodsCollectionService(params);
        if (code === 1) {
          obj.isWish === 0
            ? (this.detailsObj.isWish = 1)
            : (this.detailsObj.isWish = 0);
          $event.$emit(EVENT_NAME.LOGIN_TOKEN);
          for (let i in this.groupBrandList.productList) {
            if (obj.id === this.groupBrandList.productList[i].id) {
              //isWish
              obj.isWish === 0
                ? (this.groupBrandList.productList[i].isWish = 1)
                : (this.groupBrandList.productList[i].isWish = 0);
            }
          }
          for (let i in this.groupRelevantList.productList) {
            if (obj.id === this.groupRelevantList.productList[i].id) {
              //isWish
              obj.isWish === 0
                ? (this.groupRelevantList.productList[i].isWish = 1)
                : (this.groupRelevantList.productList[i].isWish = 0);
            }
          }
          for (let i in this.groupTitleList.productList) {
            if (obj.id === this.groupTitleList.productList[i].id) {
              //isWish
              obj.isWish === 0
                ? (this.groupTitleList.productList[i].isWish = 1)
                : (this.groupTitleList.productList[i].isWish = 0);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 新增商品提醒
     * @returns {Promise<void>}
     */
    async onGoodsRemind() {
      try {
        this.loadingStatus = true;
        let params = {
          productId: this.detailsObj.id,
          priceStart: 0,
          priceEnd: this.priceValue,
          specs: this.detailsObj.specs
        };
        const { code } = await addGoodsRemindService(params);
        if (code === 1) {
          this.alertsVisible = false;
          this.onShopGroupDetails().then();
          this.SET_ALERT_NEW(this.detailsObj.id);
          $event.$emit(EVENT_NAME.LOGIN_TOKEN);
        }
        this.loadingStatus = false;
      } catch (error) {
        this.loadingStatus = false;
        console.log(error);
      }
    },
    /**
     * 删除商品提醒
     * @returns {Promise<void>}
     */
    async onDelGoodsRemindList(id) {
      try {
        let params = {
          productId: id
        };
        const { code } = await delGoodsRemindService(params);
        if (code === 1) {
          this.onShopGroupDetails().then();
          $event.$emit(EVENT_NAME.LOGIN_TOKEN);
          this.DEL_ALERT_NEW(id);
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 查询尺码对照表
     * @returns {Promise<void>}
     */
    async onSizeGuideList(id) {
      try {
        let params = {
          productId: id
        };
        const { code, data } = await getSizeGuideListService(params);
        if (code === 1) {
          this.sizeGuideList = data;
          if (data && data.length !== 0) {
            this.sizeGuideVisible = true;
          } else {
            alert("暂无尺码表，临时提示！");
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 查询尺码对照表
     * @returns {Promise<void>}
     */
    async onGroupBrandList(id, type) {
      try {
        let params = {
          id: id,
          page: 1,
          size: 30,
          sort: "asc",
          type: type
        };
        const { code, data } = await getGroupRelevantListService(params);
        if (code === 1) {
          this.groupBrandList = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 查询商品相关推荐列表
     * @returns {Promise<void>}
     */
    async onGroupRelevantList(id, type) {
      try {
        let params = {
          id: id,
          page: 1,
          size: 30,
          sort: "asc",
          type: type
        };
        const { code, data } = await getGroupRelevantListService(params);
        if (code === 1) {
          this.groupRelevantList = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 查询商品相关推荐列表
     * @returns {Promise<void>}
     */
    async onGroupTitleList(id, type) {
      try {
        let params = {
          id: id,
          page: 1,
          size: 30,
          sort: "asc",
          type: type
        };
        const { code, data } = await getGroupRelevantListService(params);
        if (code === 1) {
          this.groupTitleList = data;
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  destroyed() {
    $event.$off([EVENT_NAME.PRODUCT_GROUP_DETAILS]);
    document.body.style.overflow = "visible";
    document.removeEventListener("mousewheel", this.handleScroll, false);
  }
};
export default ProductDetails;
