/**
 * 尺码表轮播图
 * @type {{data(): {}}}
 */
const SizeRotation = {
    name:'SizeRotation',
    props:{
        /**
         * 接收的数据
         */
        data:{
            type:Array,
            default:()=>[],
        }
    },
    data(){
        return {
            currentIndex: 1,// 当前索引
            timer: '', //定时器对象
            switchingTime:6000, //当前切换的毫秒数
        }
    },
    mounted() {
        this.onTimer()
    },
    methods:{
        /**
         * 计时器
         */
        onTimer() {
            this.timer = setInterval(() => {
                this.autoPlay()
            }, this.switchingTime)
        },
        /**
         * 点击左侧箭头切换
         */
        onSwitchLeft(){
            this.currentIndex--
            if(this.currentIndex < 1){
                this.currentIndex = this.data.length
            }
            clearInterval(this.timer)
            this.onTimer()
        },
        /**
         * 点击右侧箭头切换
         */
        onSwitchRight(){
            this.currentIndex++
            if(this.currentIndex > this.data.length){
                this.currentIndex = 1
            }
            clearInterval(this.timer)
            this.onTimer()
        },
        /**
         * 自动切换
         */
        autoPlay() {
            this.currentIndex++;
            if (this.currentIndex > this.data.length) {
                this.currentIndex = 1
            }
        },
    }
}
export default SizeRotation
