<template>
    <div class="layout-size-carousel">
        <a href="javascript:" class="size-carousel-btn" :style="'height:'+(36*data.length+21)+'px'" :class="scroll===0?'size-carousel-grey-l':'size-carousel-black-l'" @click="onSwitchLeft"></a>
        <div class="carousel-body">
            <ul>
                <li :key="key" v-for="(thead,key) in data" :style="key === rows?'background:#a8e2ca':''">{{ thead.name }}</li>
            </ul>
            <div class="carousel-list" id="sizeGuideId" @scroll="carouselScroll">
                <table v-if="data && data[0].chartList" :width="data[0].chartList.length*75+96" class="carousel-table" border="0"  cellspacing="1" cellpadding="0" >
                    <tbody>
                        <tr :key="key" v-for="(tbody,key) in data">
                            <td :key="index" v-for="(item,index) in tbody.chartList" :class="key === 0?'bold':''" :style="colorStyle(key,index)" @mouseover="changeTrColor(key,index)" @mouseout="changeTrColorOut">{{ item.value }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="carousel-page-n">
                <span class="slider" :style="'left:'+slider+'%'"></span>
            </div>
        </div>
        <a href="javascript:" class="size-carousel-btn" :style="'height:'+(36*data.length+21)+'px'" v-if="data && data[0].chartList.length!==0" :class="scroll>=((data[0].chartList.length*75+96))-offsetWidth?'size-carousel-grey-r':'size-carousel-black-r'" @click="onSwitchRight"></a>
    </div>
</template>
<script>
import SizeGuide from './sizeGuide'
export default SizeGuide
</script>
<style lang="less">
@import "sizeGuide";
</style>
