<template>
    <div class="size-box">
        <transition-group name="list" tag="ul" ref="container">
            <li v-for="(item, key) in data" :key="key" class="list-item"
                v-show="(key+1) === currentIndex"
            >
                <div class="size-content">
                    <img class="tips-image" :src="item.images">
                    <div class="tips-content">
                        <span class="tips-name">{{ $t('details.form') }} <font>{{ item.channelName }}</font></span>
                        <div class="tips-text">
                            Model: 5’10” (178cm)<br/>
                            Size Standard: XS<br/>
                            Back Length: 29” (74cm) <br/>
                            Shoulders: 13.8” (35cm) <br/>
                            Arm Length: 17.7” (45cm) <br/>
                            Bust: 33.1” (84cm) <br/>
                            Waist: 27.6” (70cm)
                        </div>
                    </div>
                </div>
            </li>
        </transition-group>
        <!-- s: circles -->
        <div class="circles-items">
            <a href="javascript:" class="circles-l circles-arrow" @click="onSwitchLeft()"></a>
            <a class="circles-n" :key="index" v-for="index in data.length" :class="{'active':index===currentIndex}"></a>
            <a href="javascript:" class="circles-r circles-arrow" @click="onSwitchRight()"></a>
        </div>
        <!-- e: circles -->
    </div>
</template>
<script>
import SizeRotation from './sizeRotation'
export default SizeRotation
</script>
<style lang="less">
@import "sizeRotation";
</style>
