/**
 * 尺码表轮播图组件
 * @type {{data(): {}}}
 */
import Vue from 'vue'
import VueLazyLoad from 'vue-lazyload'
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import { onCharacterPrice } from "@/utils/tools";
import { mapState } from "vuex";
Vue.use(VueLazyLoad,{
    loading:require('@/assets/Loading/seize-seat-img.jpg')
})
const SizeGuide = {
    name:'SizeGuide',
    props:{
        /**
         * 接收的数据
         */
        data:{
            type:Array,
            default:()=>[],
        },
    },
    computed:{
        ...mapState({
            currencyCode: state => state.system.currencyCode, //获取状态管理币种代码
        }),
        colorStyle(){
            return (key,index) => {
                let color = ''
                if(key === this.rows || index=== this.columns && key!==0){
                    color = 'background:#e5f6ef'
                }
                if(index=== this.columns && key === 0){
                    color = 'background: rgba(168,226,202)'
                }
                return color
            }
        }
    },
    data(){
        return {
            onCharacterPrice:onCharacterPrice,
            currentIndex: 0,// 当前索引
            currentSpace:0,
            number:5, //显示的条数
            scroll:0,
            offsetWidth:0,
            rows:'', //当前行
            columns:'', //当前列
            slider:0, //滑块
        }
    },
    mounted() {
        if(this.data.length!==0){
            this.offsetWidth = Math.ceil(document.getElementById('sizeGuideId').offsetWidth)+1
        }
        /**
         * carousel宽
         */
        $event.$on(EVENT_NAME.CAROUSEL_OFFSET_WIDTH, () => {
            this.offsetWidth = Math.ceil(document.getElementById('sizeGuideId').offsetWidth)+1
        });
    },
    methods: {
        /**
         * 监听超卖滚动条
         */
        carouselScroll(e) {
            this.scroll = Math.ceil(e.srcElement.scrollLeft)
            let width = (this.data[0].chartList.length*75+96)-this.offsetWidth
            this.slider = this.scroll/width*100/2
        },
        /**
         * 点击左侧箭头切换
         */
        onSwitchLeft(){
            if(this.scroll >= 0){
                document.getElementById('sizeGuideId').scrollLeft -= this.offsetWidth
            }else{
                document.getElementById('sizeGuideId').scrollLeft =  0
            }
            this.scroll = document.getElementById('sizeGuideId').scrollLeft
        },
        /**
         * 点击右侧箭头切换
         */
        onSwitchRight(){
            if(this.scroll <= (this.data[0].chartList.length*75+96)-this.offsetWidth){
                document.getElementById('sizeGuideId').scrollLeft += this.offsetWidth
            }else{
                document.getElementById('sizeGuideId').scrollLeft =  (this.data[0].chartList.length*75+96)-this.offsetWidth
            }
            this.scroll = document.getElementById('sizeGuideId').scrollLeft
        },
        /**
         * 监听行、列
         * @param key
         * @param index
         */
        changeTrColor(key,index){
            this.rows = key
            this.columns = index
        },
        /**
         * 鼠标移走清空
         */
        changeTrColorOut(){
            this.rows = ''
            this.columns = ''
        }
    },
    /**
     * 销毁事件
     */
    destroyed() {
        $event.$off([EVENT_NAME.CAROUSEL_OFFSET_WIDTH])
    }
}
export default SizeGuide
